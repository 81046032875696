<template>
  <div>
    <GenericTable
      ref="lista"
      name="tipo de instrumento"
      title="Tipos de Instrumento"
      add-route="tipoInstrumentoAdicionar"
      edit-route="tipoInstrumentoEditar"
      :cols="['Descrição']"
      :cols-name="['descricao']"
      :cols-map="(i) => [i.descricao]"
      :route="tipoInstrumentoRoute"
      :permissionsToWrite="['rw_material']"
      :filters="filters"
      @clear-filters="clearFilters"
      :hasPagination="true"
      idKey="id_tipo_item"
    >
      <template #filters>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>Descrição</label>
              <b-form-input
                v-model="filters.descricao"
                autocomplete="off"
                class="invision-input sm"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>Fabricante</label>
              <b-form-input
                v-model="filters.fabricante"
                autocomplete="off"
                class="invision-input sm"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
    </GenericTable>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tipoInstrumentoRoute: 'tipoItem',
      filters: {
        descricao: '',
        fabricante: '',
      },
    };
  },
  methods: {
    clearFilters() {
      this.filters.descricao = '';
      this.filters.fabricante = '';
    },
  },
};
</script>
